<template>
  <div class="wrapper">
    <van-cell-group title="商品明细">
      <template v-for="(item, index) in obj">
        <van-cell
          v-if="item.typeArr"
          :key="index"
          :title="item.name"
          :value="item.typeArr[detail[item.index]]"
        />
        <van-cell
          v-else-if="item.cache"
          :key="index"
          :title="item.name"
          :value="cacheData[item.index][detail[item.index]] || '-'"
        />
        <van-cell
          v-else
          :key="index"
          :title="item.name"
          :value="detail[item.index]"
        />
      </template>
    </van-cell-group>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      obj: [
        { sort: 0, name: '商品编号', index: 'goods_no', display: true, sortable: true },
        { sort: 1, name: '名称', index: 'name', display: true, sortable: false },
        { sort: 2, name: '商品分类', index: 'goods_cate', display: true, sortable: false, cache: 'goods_cate' },
        { sort: 3, name: '商品单位', index: 'unit', display: true, sortable: false, cache: 'unit' },
        { sort: 4, name: '属性', index: 'attribute', display: true, sortable: false, typeArr: { 1: '精品', 2: '礼品' } },
        { sort: 5, name: '封面图', index: 'cover_img', display: true, sortable: false },
        { sort: 6, name: '商城是否可见', index: 'store_visible', display: true, sortable: true, typeArr: { 1: '是', 2: '否' } },
        { sort: 7, name: '包装数量', index: 'pack', display: true, sortable: true },
        { sort: 8, name: '型号', index: 'model', display: true, sortable: false },
        { sort: 9, name: '销售价格', index: 'sale_price', display: true, sortable: true },
        { sort: 10, name: '成本价', index: 'cost_price', display: true, sortable: true },
        // { sort: 11, name: '适用车型', index: 'available_model', display: true, sortable: false },
        { sort: 12, name: '仓库', index: 'warehouse', display: true, sortable: false, cache: 'warehouse' },
        { sort: 13, name: '仓位', index: 'storage', display: true, sortable: false, cache: 'storage' },
        { sort: 14, name: '兑换积分', index: 'exchange_score', display: true, sortable: true },
        { sort: 15, name: '占用库存', index: 'take_stock', display: true, sortable: true },
        { sort: 16, name: '库存', index: 'stock', display: true, sortable: true },
        { sort: 17, name: '销售默认选择', index: 'seller_default_select', display: true, sortable: false, typeArr: { 1: '是', 2: '否' } },
        { sort: 18, name: '辅助编号', index: 'goods_no_alias', display: true, sortable: true }
      ],
      cacheData: null
    }
  },
  mounted () {
    this.cacheData = this.$store.getters.cache
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  height: 100vh;
  overflow-y: auto;
}
</style>