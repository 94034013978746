<template>
  <div class="app-container">
    <van-sticky>
      <!-- 这里是搜索 -->
    </van-sticky>
    <van-cell-group title="">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell
          center
          clickable
          :title="'商品名称：' + item.name"
          v-for="(item, index) in dataList"
          :key="index"
          @click="showDesc(item)"
        >
          <template #label>
            <div style="width: 100%" class="label-content">
              <!-- <van-image
                width="80px"
                height="80px"
                fit="cover"
                src="https://img.yzcdn.cn/vant/cat.jpeg"
              /> -->
              <div class="main">
                <strong>商品编号：</strong> <span>{{ item.goods_no }}</span>
                <br />
                <strong>销售价格：</strong>
                <span>{{ item.sale_price / 100 }}</span>
                <br />
                <strong>兑换积分：</strong>
                <span>{{ item.exchange_score }}</span>
              </div>
            </div>
          </template>
          <van-button
            plain
            type="info"
            size="mini"
            v-if="item.status === 2"
            @click.stop="setStatus(item)"
            >启用</van-button
          >
          <van-button
            plain
            type="danger"
            size="mini"
            v-if="item.status === 1"
            @click.stop="setStatus(item)"
            >禁用</van-button
          >
        </van-cell>
      </van-list>
    </van-cell-group>

    <van-popup v-model="show" position="right" :style="{ height: '100%', width: '100%' }" closeable>
      <Detail :detail="detail" />
    </van-popup>
  </div>
</template>
<script>
import Detail from './components/detail'
export default {
  components: {
    Detail
  },
  data () {
    return {
      loading: false,
      finished: false,
      dataList: [],
      total: 0,
      query: {
        page: 1
      },
      detail: {},
      show: false
    }
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    onLoad () {
      setTimeout(() => {
        this.getDataList()
        this.query.page++
      }, 500)
    },
    getDataList () {
      this.$axios.post('/goods/lists', this.query).then(res => {
        res.data.list.map(item => {
          this.dataList.push(item)
        })
        this.total = res.data.total
        this.loading = false
        // 数据全部加载完成
        if (this.dataList.length >= res.data.total) {
          this.finished = true
        }
      })
    },
    setStatus (item) {
      item.status = item.status === 1 ? 2 : 1
      this.$axios.post('/goods/status', {
        goods_no: item.goods_no,
        status: item.status
      })
    },
    showDesc(data){
      this.detail = data
      this.show = true
    }
  }
}
</script>
<style lang="scss" scoped>
.label-content {
  display: flex;
}
</style>